import React, {FunctionComponent} from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import {Link} from "react-router-dom";

export interface SidebarProps {
    items: SidebarItem[];
}

export interface SidebarItemProps extends SidebarItem {}

export interface SidebarItem {
    label: string;
    name: string;
    path: string;
    items?: SidebarItem[];
    depthStep?: number;
    depth?: number;
}


export const SidebarItem: FunctionComponent<SidebarItemProps> = ({ label, items, path, depthStep = 10, depth = 0 }) => {
    return (
        <>
            <Link to={path}>
                <ListItem button dense>
                    <ListItemText style={{ paddingLeft: depth * depthStep }}>
                        <span>{label}</span>
                    </ListItemText>
                </ListItem>
            </Link>
            {Array.isArray(items) ? (
                <List disablePadding dense>
                    {items.map((subItem) => (
                        <SidebarItem
                            key={subItem.name}
                            depth={depth + 1}
                            depthStep={depthStep}
                            {...subItem}
                        />
                    ))}
                </List>
            ) : null}
        </>
    )
};

export const Sidebar: FunctionComponent<SidebarProps> = ({items}) => {
    return (
        <div className="sidebar">
            <List disablePadding dense>
                {items.map((sidebarItem, index) => (
                    <SidebarItem
                        key={`${sidebarItem.name}${index}`}
                        label={sidebarItem.label}
                        name={sidebarItem.name}
                        path={sidebarItem.path}
                        depthStep={sidebarItem.depthStep}
                        depth={sidebarItem.depth}
                        items={sidebarItem.items}
                    />
                ))}
            </List>
        </div>
    )
};

