export class LocalStorage {
    public static getValue<T>(key: string): T | null {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : null;
        } catch (error) {
            console.log(error);
            return null;
        }
    }
    public static setValue<T>(key: string, value: T) {
        try {
            window.localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.error(error);
        }
        return value;
    };
}
