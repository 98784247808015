import React, {ChangeEvent, FunctionComponent, useState} from "react";
import {Sudoku} from "./sudoku/Sudoku";
import {Button} from "@material-ui/core";
import {BoardState, Difficulty, getRandomPuzzle, serializePuzzle} from "./sudoku/SudokuHelperMethods";
import * as firebase from "firebase";
import {useHistory} from "react-router";
import {ToggleButtonGroup} from "@material-ui/lab";
import ToggleButton from "@material-ui/lab/ToggleButton";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {getAnonymousId} from "./utils/StoredAnonymousIdentifier";
import {useResponsive} from "./hooks/useResponsive";

export interface CreateSudokuPageProps {}

export const CreateSudokuPage: FunctionComponent<CreateSudokuPageProps> = () => {

    const [difficulty, setDifficulty] = useState<Difficulty>("MEDIUM");
    const [isPublic, setIsPublic] = useState<boolean>(false);
    const [name, setName] = useState<string>("Sudoku " + Math.floor(Math.random() * 1000));
    const [puzzle, setPuzzle] = useState<BoardState>(getRandomPuzzle(difficulty));
    const {viewport} = useResponsive();
    const history = useHistory();

    const handleCreateButton = () => {

        const serializedPuzzle = serializePuzzle(puzzle);
        const sudokuToSave = {initialPuzzle: serializedPuzzle, puzzle: serializedPuzzle, name}
        if (isPublic) {
            const sudokusRef = firebase.database().ref('sudokus').push();
            sudokusRef.set(sudokuToSave);
            history.push(`/sudoku/public/${sudokusRef.key}`);
        } else {
            const sudokusRef = firebase.database().ref(`anonymousPlayers/${getAnonymousId()}/sudokus`).push();
            sudokusRef.set(sudokuToSave);
            history.push(`/sudoku/solo/anon/${sudokusRef.key}`);
        }

    };

    const handleDifficulty = (event: any, value: Difficulty) => {
        setDifficulty(value);
        setPuzzle(getRandomPuzzle(value))
    };
    const handleName = (event: ChangeEvent<HTMLInputElement>) => {setName(event.target.value);};
    const handleIsPublic = (event: ChangeEvent<HTMLInputElement>) => {setIsPublic(event.target.checked);};

    return <>
        <h1 className={"text-3xl"}>Create a Sudoku</h1>
        <br/>
        <div className={"py-1 flex justify-center"}>
            <TextField variant={"outlined"} label="Sudoku Name" value={name} onChange={handleName}/>
            <FormControlLabel
                className={"px-4"}
                control={<Switch checked={isPublic} onChange={handleIsPublic} name="is public" />}
                label="Public"
            />
        </div>
        <div className={"py-1 flex justify-center"}>
            <ToggleButtonGroup
                value={difficulty}
                exclusive
                onChange={handleDifficulty}
                aria-label="difficulty"
                size={viewport === "phone" ? "small" : "medium"}
            >
                <ToggleButton value="VERY_EASY" aria-label="very easy">
                    <span className="text-xs md:text-sm">Very Easy</span>
                </ToggleButton>
                <ToggleButton value="EASY" aria-label="easy">
                    <span className="text-xs md:text-sm">Easy</span>
                </ToggleButton>
                <ToggleButton value="MEDIUM" aria-label="medium">
                    <span className="text-xs md:text-sm">Medium</span>
                </ToggleButton>
                <ToggleButton value="HARD" aria-label="hard">
                    <span className="text-xs md:text-sm">Hard</span>
                </ToggleButton>
                <ToggleButton value="VERY_HARD" aria-label="very hard">
                    <span className="text-xs md:text-sm">Very Hard</span>
                </ToggleButton>
                <ToggleButton value="NIGHTMARE" aria-label="nightmare">
                    <span className="text-xs md:text-sm">Nightmare</span>
                </ToggleButton>
            </ToggleButtonGroup>
        </div>
        <div className={"py-1 flex content-around justify-center"}>
            <div className={"mx-2"} ><Button onClick={() => setPuzzle(getRandomPuzzle(difficulty))} variant="contained">Generate</Button></div>
            <div className={"mx-2"} ><Button onClick={handleCreateButton} variant="contained" color={"primary"}>Play</Button></div>
        </div>
        <Sudoku hiddenNumbers initialBoardState={puzzle} boardState={puzzle} readonly/>
    </>;
};
