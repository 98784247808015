import React, {Component, FunctionComponent} from 'react';
import {BoardPuzzle, Notes, ValidSudokuInput} from './BoardPuzzle';

import {
    BoardState, handleNotesChange,
    handleSquareValueChange,
} from "./SudokuHelperMethods";

export interface SudokuProps {
    boardState: BoardState;
    initialBoardState: BoardState;
    readonly?: boolean;
    onChange?: (boardState: BoardState) => void
    hiddenNumbers?: boolean;
    notes?: Notes;
    onNotesChanges?: (notes: Notes) => void;
}

export const Sudoku: FunctionComponent<SudokuProps> = (props) => {

    const handleSquareChange = (i: number, j: number, newValue: ValidSudokuInput, isNote = false) => {
        console.log(i, j, newValue, isNote, props.onNotesChanges);
        if (isNote && props.onNotesChanges) {
            props.onNotesChanges(handleNotesChange(props.notes || {}, i, j, newValue))
        } else if(props.onChange) {
            props.onChange(handleSquareValueChange(props.boardState, i, j, newValue));
        }
    };
    return (
        <div className={`Sudoku ${props.readonly ? 'readonly' : ''} ${props.hiddenNumbers ? 'hiddenNumbers' : ''}`}>
            <BoardPuzzle
                initialBoardState={props.initialBoardState}
                boardState={props.boardState}
                onSquareValueChange={handleSquareChange}
                readonly={props.readonly}
                notes={props.notes || {}}
            />
        </div>
    );
}


