import React, {FunctionComponent} from "react";
import {ChatInput} from "./ChatInput";
import {ChatMessage} from "./ChatMessage";

export interface ChatProps {}

export const Chat: FunctionComponent<ChatProps> = () => {
    return <>
        <div className={"flex flex-col h-full"}>
            <div className={'p-4'}>Header</div>
            <div className={"flex-1 p-4"}>
                <ChatMessage date={"15:00"} content={"Wassup"} type={"sent"}/>
                <ChatMessage date={"15:07"} content={"Waaaazaaaa!"} type={"received"}/>
                <ChatMessage date={"15:12"} content={"Ça roule mon pote?"} type={"sent"}/>
                <ChatMessage date={"15:15"} content={"Yaaa"} type={"received"}/>
                <ChatMessage date={"15:16"} content={"Tu peux me prêter 20$?"} type={"sent"}/>
                <ChatMessage date={"15:00"} content={"Bingo a quitté le chat"} type={"system"}/>
            </div>
            <ChatInput/>
        </div>
    </>;
};
