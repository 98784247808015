import React, {FunctionComponent} from "react";
import {Sudoku} from "./sudoku/Sudoku";
import {useEffect, useState} from "react";
import {BoardState, deserializePuzzle, getEmptyBoardState, serializePuzzle} from "./sudoku/SudokuHelperMethods";
import {useParams} from "react-router";
import * as firebase from "firebase";
import Confetti from "react-dom-confetti";
import * as _ from 'underscore';
import {Button} from "@material-ui/core";
import {Chat} from "./chat/Chat";
import Toolbar from "@material-ui/core/Toolbar";
import {Notes} from "./sudoku/BoardPuzzle";

export interface SudokuPageProps {}

export const SudokuPage: FunctionComponent<SudokuPageProps> = () => {
    const [puzzle, setPuzzle] = useState<BoardState>(getEmptyBoardState());
    const [notes, setNotes] = useState<Notes>({});
    const [name, setName] = useState<string>();
    const [initialPuzzle, setInitialPuzzle] = useState<BoardState>(getEmptyBoardState());
    const params = useParams<{id: string}>();


    useEffect(() => {
        /*firebase.database().ref(`sudokus/${params.id}/`).on('value', (snapshot) => {
            setPuzzle(deserializePuzzle(snapshot.val().puzzle));
            setName(snapshot.val().name);
            setInitialPuzzle(deserializePuzzle(snapshot.val().initialPuzzle));
            setNotes(snapshot.val().notes);
        });*/
        firebase.database().ref(`sudokus/${params.id}/puzzle`).on('value', (snapshot) => {
            setPuzzle(deserializePuzzle(snapshot.val()));
        });
        firebase.database().ref(`sudokus/${params.id}/name`).on('value', (snapshot) => {
            setName(snapshot.val());
        });
        firebase.database().ref(`sudokus/${params.id}/initialPuzzle`).on('value', (snapshot) => {
            setInitialPuzzle(deserializePuzzle(snapshot.val()));
        });
        firebase.database().ref(`sudokus/${params.id}/notes`).on('value', (snapshot) => {
            setNotes(snapshot.val());
        });
    }, [params]);

    const handleSudokuChange = (newPuzzle: BoardState) => {
        if (serializePuzzle(newPuzzle) !==  serializePuzzle(puzzle)) {
            setPuzzle(newPuzzle);
            firebase.database().ref(`sudokus/${params.id}/puzzle`).set(serializePuzzle(newPuzzle));
        }
    };
    const handleSudokuNotesChange = (newNotes: Notes) => {
        setNotes(newNotes);
        firebase.database().ref(`sudokus/${params.id}/notes`).set(newNotes);
    };

    return <div className={"flex h-full"}>
        <div className={"flex-1"}>
            <Toolbar>
                <h3 className={"text-3xl flex-grow"}>{name || ""}</h3>
                <Button variant="contained" color="primary" onClick={() => {
                    handleSudokuChange(initialPuzzle);
                    handleSudokuNotesChange({});
                }}>Reset</Button>
            </Toolbar>
            <Sudoku
                initialBoardState={initialPuzzle}
                boardState={puzzle}
                onChange={handleSudokuChange}
                onNotesChanges={handleSudokuNotesChange}
                notes={notes}
            />
            <div style={{margin: "auto", width: '10px'}}>
                <Confetti active={!_.contains(serializePuzzle(puzzle), "0")} config={{spread: 90, elementCount: 75, stagger: 2}}/>
            </div>
        </div>
        <div className={"hidden"}>
            <Chat/>
        </div>
    </div>;
};
