import React, {FunctionComponent, useEffect, useState} from "react";
import * as firebase from "firebase";
import {useHistory} from "react-router";
import {SudokuList} from "./SudokuList";
import {SudokuModelMap} from "./SudokuModel";
import {SquareNotes} from "./sudoku/SquareNotes";

export interface PublicSudokusPageProps {}

export const PublicSudokusPage: FunctionComponent<PublicSudokusPageProps> = () => {
    const [puzzles, setPuzzles] = useState<SudokuModelMap>({});
    const history = useHistory();
    useEffect(() => {
        firebase.database().ref(`sudokus`).on('value', (snapshot) => {
            setPuzzles(snapshot.val());
        });
    }, []);

    return <>
        <h1 className={'text-4xl'}>Public Sudokus</h1>
        <SudokuList puzzleModels={puzzles} onOpen={(id) => history.push(`/sudoku/public/${id}`)}/>
    </>;
};
