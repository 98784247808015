import {Cell} from "./Cell";
import * as solver from "@bbunderson/sudoku-solver";
import {SudokuGenerator} from "./SudokuGenerator";
import * as _ from "underscore";
import {Notes, ValidSudokuInput} from "./BoardPuzzle";

export type BoardState = Cell[][];

export type Difficulty = "VERY_EASY" | "EASY" | "MEDIUM" | "HARD" | "VERY_HARD" | "NIGHTMARE";

const difficulties: any = {
    "VERY_EASY": "easy",
    "EASY": "medium",
    "MEDIUM": "hard",
    "HARD": "very-hard",
    "VERY_HARD": "insane",
    "NIGHTMARE": "inhuman"
};

export const getRandomPuzzle = (lvl: Difficulty) => deserializePuzzle(_.map((new SudokuGenerator()).generate(difficulties[lvl] || "hard").split(''), (v: string) => v === '.' ? 0 : v).join(''));

export const verify = (boardState: BoardState) => {
    const rows: Map<number, Cell[]> = new Map([]);
    const cols: Map<number, Cell[]> = new Map([]);
    const boxes: Map<string, Cell[]> = new Map([]);

    for(let i=0; i < boardState.length; i++) {
        rows.set(i, getDeepCopyOfArray(boardState[i]));

        for(let j=0; j<boardState[i].length;j++) {
            // populating cols
            if(cols.has(j)) {
                cols.get(j)!.push(boardState[i][j])
            } else {
                cols.set(j, [boardState[i][j]]);
            }

            // populating boxes
            const boxId: string = stringify(Math.floor(i/3), Math.floor(j/3));
            if(boxes.has(boxId)) {
                boxes.get(boxId)!.push(boardState[i][j])
            } else {
                boxes.set(boxId, [boardState[i][j]]);
            }
        }
    }

    const rowConflicts = flatten(getConflicts(Array.from(rows.values())));
    const colConflicts = flatten(getConflicts(Array.from(cols.values())));
    const boxConflicts = flatten(getConflicts(Array.from(boxes.values())));


    return new Set([...rowConflicts, ...colConflicts, ...boxConflicts]);
};

export const solvePuzzle = (puzzle: string) => {
    solver.solve_string(puzzle);
};

export const verifySquare = (i: number, j: number, boardState: BoardState, initialPuzzle: BoardState) => {
    return boardState[i][j].cellValue === deserializePuzzle(solver.solve_string(serializePuzzle(initialPuzzle)))[i][j].cellValue;
};

export const verifyValue = (i: number, j: number, value: string, initialPuzzle: string) => {
    return value === deserializePuzzle(solver.solve_string(initialPuzzle))[i][j].cellValue;
};

export const deserializePuzzle = (puzzle: string) => {
    const boardState = createPuzzleArray();
    for(let i=0; i<puzzle.length; i++) {
        const rowId = getRowId(i);
        const colId = getColId(i);

        boardState[rowId][colId] = {
            cellValue: puzzle[i],
            cellId: stringify(rowId, colId),
        };
    }
    return boardState;
};

export const serializePuzzle = (boardState: BoardState) => {
    let puzzleText = "";
    for(let i=0; i < boardState.length; i++) {
        for (let j = 0; j < boardState[i].length; j++) {
            puzzleText = puzzleText + boardState[i][j].cellValue;
        }
    }
    return puzzleText;
};

export const handleSquareValueChange = (boardState: BoardState, i: number, j: number, newValue: string) => {
    const newBoardState: BoardState = getDeepCopyOfArray(boardState);
    newBoardState[i][j] = {
        cellValue: newValue,
        cellId: stringify(i, j),
        valid: false,
    };
    return newBoardState
};

export const handleNotesChange = (notes: Notes, i: number, j: number, value: ValidSudokuInput) => {
    const newNotes: Notes = getDeepCopyOfArray(notes);
    const currentNotes = newNotes['' + i + j] || [];
    if (_.contains(currentNotes, value)) {
        currentNotes.splice(currentNotes.indexOf(value), 1)
    } else if(currentNotes) {
        currentNotes.push(value);
    }
    newNotes['' + i + j] = currentNotes;
    return newNotes;
};


export const getEmptyBoardState = () => deserializePuzzle(new Array(9*9).fill('0').join(''));

const getConflicts = (arrs: Cell[][]) => {
    return arrs.map(arr => getConflictsInArray(arr));
};

const getConflictsInArray = (arr: Cell[]) => {
    const conflictMap: Map<string, string[]> = new Map([]);
    for(let i=0; i<arr.length; i++) {
        let curr = arr[i];
        if(curr.cellValue !== "0") {
            if(conflictMap.has(curr.cellValue)) {
                conflictMap.get(curr.cellValue)!.push(curr.cellId);
            } else {
                conflictMap.set(curr.cellValue, [curr.cellId]);
            }
        }
    }
    return Array.from(conflictMap.values()).filter((arr: string[]) => arr.length>1);
};


const flatten = (a: any): any => {
    return Array.isArray(a) ? [].concat(...a.map(flatten)) : a;
};

const stringify = (num1: number, num2: number) => {
    return num1 + '' + num2;
};

const getRowId = (i: number) => {
    return Math.floor(i/9);
};

const getColId = (i: number) => {
    return (i%9);
};

const getDeepCopyOfArray = (arr: any) => {
    return JSON.parse(JSON.stringify(arr));
};

const createPuzzleArray = () => {
    return [[], [], [], [], [], [], [], [], []].map(() => new Array(9));
};

