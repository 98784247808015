import React, {FunctionComponent} from 'react';
import {SquareLocation, ValidSudokuInput} from "./BoardPuzzle";
import {SquareNotes} from "./SquareNotes";

interface SquareProps {
    editable: boolean;
    value: string;
    rowIndex: number;
    colIndex: number;
    size: SquareSize;
    selectedSquare?: SquareLocation;
    selectedValue?: string;
    onClick: () => void;
    notes: ValidSudokuInput[];
}

export type SquareSize = "normal" | "small";

export const Square: FunctionComponent<SquareProps> = ({editable, value, size, rowIndex, colIndex, selectedSquare, selectedValue, onClick, notes}) => {
    const squareValue = value === '0' ? "" : value;
    const classes = ['Square'];
    if(rowIndex > 0 && rowIndex % 3 === 0) {
        classes.push('thickTop');
    }
    if(colIndex > 0 && colIndex % 3 === 0) {
        classes.push('thickLeft');
    }
    if(!editable) {
        classes.push('disabled');
    }
    if(size === "small") {
        classes.push("small");
    }
    if (selectedSquare) {
        const sameCol = selectedSquare.column === colIndex;
        const sameRow = selectedSquare.row === rowIndex;
        const sameBoxe = Math.floor(selectedSquare.row/3) === Math.floor(rowIndex/3) && Math.floor(selectedSquare.column/3) === Math.floor(colIndex/3); // @todo check for same boxe
        if(sameCol && sameRow) {
            classes.push('selected');
        } else if(sameRow || sameCol || sameBoxe) {
            classes.push('related');
        }
        if (selectedValue && selectedValue !== '0' && value !== '0' && value === selectedValue) {
            classes.push('same-value')
        }
    }

    return (
        <td onClick={onClick}>
            <div className={`relative ${classes.join(' ')}`}>
                {squareValue
                    ? squareValue
                    : <SquareNotes value={notes}/>
                }
            </div>
        </td>
    );
};
