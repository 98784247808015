import React, {FunctionComponent} from "react";
import {Backspace, Edit,} from "@material-ui/icons";
import {ValidSudokuInput} from "./BoardPuzzle";
import {IconButton} from "@material-ui/core";
import Button from "@material-ui/core/Button";

export interface SudokuPadProps {
    onClick: (value: ValidSudokuInput) => void;
    onNoteModeClick: () => void;
    noteMode: boolean;
}

export const SudokuPad: FunctionComponent<SudokuPadProps> = ({onClick, onNoteModeClick, noteMode}) => {
    return <div className="p-4 mt-4 text-center">
        <IconButton color="primary" onClick={() => onClick("1")}><span className="mx-2 px-1 text-2xl">1</span></IconButton>
        <IconButton color="primary" onClick={() => onClick("2")}><span className="mx-2 px-1 text-2xl">2</span></IconButton>
        <IconButton color="primary" onClick={() => onClick("3")}><span className="mx-2 px-1 text-2xl">3</span></IconButton>
        <IconButton color="primary" onClick={() => onClick("4")}><span className="mx-2 px-1 text-2xl">4</span></IconButton>
        <IconButton color="primary" onClick={() => onClick("5")}><span className="mx-2 px-1 text-2xl">5</span></IconButton>
        <IconButton color="primary" onClick={() => onClick("6")}><span className="mx-2 px-1 text-2xl">6</span></IconButton>
        <IconButton color="primary" onClick={() => onClick("7")}><span className="mx-2 px-1 text-2xl">7</span></IconButton>
        <IconButton color="primary" onClick={() => onClick("8")}><span className="mx-2 px-1 text-2xl">8</span></IconButton>
        <IconButton color="primary" onClick={() => onClick("9")}><span className="mx-2 px-1 text-2xl">9</span></IconButton>
        <IconButton color="primary" onClick={() => onClick("0")}><Backspace/></IconButton>
        <Button color="primary" onClick={onNoteModeClick}>
            <Edit/> Note mode {noteMode ? "On" : "Off"}
        </Button>
    </div>;
};
