import React, {FunctionComponent, useState} from "react";
import {TextField} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import * as s from "underscore.string";
import {getPseudonym, setPseudonym} from "./utils/StoredPseudonym";

export interface AnonymousNameChooserProps {
    onClose: () => void;
    open: boolean;
}

export const AnonymousNameChooser: FunctionComponent<AnonymousNameChooserProps> = ({onClose, open}) => {
    const [value, setValue] = useState<string>(getPseudonym());

    return <Dialog aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">Please choose a pseudonym</DialogTitle>
        <div className={"p-4 flex"}>
            {
                value
                    ? <div className={'h-16 w-16 rounded-full'}><img src={`https://avatars.dicebear.com/v2/bottts/${s.slugify(value)}.svg`} alt={"avatar"}/></div>
                    : <Avatar className={"mr-2"}>?</Avatar>
            }
            <TextField value={value} onChange={(event) => setValue(s.slugify(event.target.value))}/>
        </div>
        <Button disabled={!value} color={"primary"} onClick={() => {
            setPseudonym(value);
            onClose();
        }}>Confirm</Button>
    </Dialog>;
};
