import React, {FunctionComponent, useEffect, useState} from "react";
import {getAnonymousId} from "./utils/StoredAnonymousIdentifier";
import {useHistory} from "react-router";
import * as firebase from "firebase";
import {SudokuList} from "./SudokuList";
import {SudokuModelMap} from "./SudokuModel";

export interface AnonymSoloSudokuPageProps {}

export const AnonymSoloSudokusPage: FunctionComponent<AnonymSoloSudokuPageProps> = () => {
    const [puzzles, setPuzzles] = useState<SudokuModelMap>({});
    const history = useHistory();
    useEffect(() => {
        firebase.database().ref(`anonymousPlayers/${getAnonymousId()}/sudokus`).on('value', (snapshot) => {
            setPuzzles(snapshot.val());
        });
    }, []);
    return <>
        <h1 className={"text-3xl"}>Solo Sudokus</h1>
        <SudokuList puzzleModels={puzzles} onOpen={(id) => history.push(`/sudoku/solo/anon/${id}`)}/>
    </>;
};
