import React, {FunctionComponent, useState} from "react";
import {AppBar} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {useResponsive} from "../hooks/useResponsive";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import * as s from "underscore.string";
import {Edit} from "@material-ui/icons";
import {Sidebar} from "./Sidebar";
import Drawer from "@material-ui/core/Drawer/Drawer";
import {items} from "./Layout";
import {AnonymousNameChooser} from "../anonymousNameChooser";
import {getPseudonym} from "../utils/StoredPseudonym";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router";
import {useAuthenticationService} from "clean-authentication";

export const ApplicationBar: FunctionComponent = () => {
    const {viewport} = useResponsive();
    const [nameChooserOpen, setNameChooserOpen] = useState<boolean>(false);
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const {isAuthenticated, authenticationService} = useAuthenticationService();
    const history = useHistory();

    return <>
        <AppBar position="static" style={{paddingLeft: viewport === "desktop" ? '240px' : 0}}>
            <Toolbar>
                {
                    (viewport === "tablet" || viewport === "phone")
                        ? <IconButton onClick={() => setDrawerOpen(true)} edge="start" color="inherit" aria-label="menu">
                            <MenuIcon/>
                        </IconButton>
                        : ""
                }
                <Typography variant="h6">
                    Sudoku
                </Typography>
                <div className={'flex flex-grow'} />
                { isAuthenticated
                    ? <>
                        <Button
                            variant="contained"
                            onClick={() => {
                                authenticationService.logout();
                            }}
                        >
                            Sign Out
                        </Button>
                    </>
                    : <>
                        <Button
                            variant="contained"
                            onClick={() => {
                                history.push('/auth/signin');
                            }}
                        >
                            Sign In
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                history.push('/auth/signup');
                            }}
                        >
                            Sign up
                        </Button>
                    </>
                }

            </Toolbar>
        </AppBar>
        <Drawer
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            anchor="left"
            style={{width: '300px'}}
        >
            <AnonymousNameChooser open={nameChooserOpen} onClose={() => setNameChooserOpen(false)}/>
            <img className={"rounded-full my-4 w-1/2 mx-auto"} src={`https://avatars.dicebear.com/v2/bottts/${s.slugify(getPseudonym())}.svg`} alt={"avatar"}/>
            <h3 className={'p2 text-2xl mx-auto'}>{getPseudonym()}<IconButton onClick={() => setNameChooserOpen(true)}><Edit/></IconButton></h3>
            <Sidebar items={items}/>
        </Drawer>
    </>;
};
