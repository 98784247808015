import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'
import * as serviceWorker from './serviceWorker';
import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/database';
import 'firebase/auth';
import {ViewportProvider} from "./hooks/useViewport";
import {App} from "./App";

// Initialize Firebase
firebase.initializeApp({
    apiKey: "AIzaSyClYu-yVLPgexOqBDEwGzvOtTV7b9JmIMI",
    authDomain: "sudoku-5885b.firebaseapp.com",
    databaseURL: "https://sudoku-5885b.firebaseio.com",
    projectId: "sudoku-5885b",
    storageBucket: "sudoku-5885b.appspot.com",
    messagingSenderId: "246427992437",
    appId: "1:246427992437:web:14db94398fa55d2eb7133c",
    measurementId: "G-LKT607RN35"
});
firebase.analytics();


ReactDOM.render(
    <React.StrictMode>
        <ViewportProvider><App/></ViewportProvider>
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
