import React, {FunctionComponent, useEffect, useState} from "react";
import Toolbar from "@material-ui/core/Toolbar";
import {Button} from "@material-ui/core";
import {Sudoku} from "./sudoku/Sudoku";
import Confetti from "react-dom-confetti";
import * as _ from "underscore";
import {BoardState, deserializePuzzle, getEmptyBoardState, serializePuzzle} from "./sudoku/SudokuHelperMethods";
import {useParams} from "react-router";
import * as firebase from "firebase";
import {getAnonymousId} from "./utils/StoredAnonymousIdentifier";
import {Notes} from "./sudoku/BoardPuzzle";

export interface SudokuSoloPageProps {}

export const SudokuSoloPage: FunctionComponent<SudokuSoloPageProps> = () => {
    const [puzzle, setPuzzle] = useState<BoardState>(getEmptyBoardState());
    const [notes, setNotes] = useState<Notes>({});
    const [name, setName] = useState<string>();
    const [initialPuzzle, setInitialPuzzle] = useState<BoardState>(getEmptyBoardState());
    const params = useParams<{sudokuId: string}>();

    useEffect(() => {
        /*
            firebase.database().ref(`anonymousPlayers/${getAnonymousId()}/sudokus/${params.sudokuId}`).on('value', (snapshot) => {
                setPuzzle(deserializePuzzle(snapshot.val().puzzle));
                setName(snapshot.val().name);
                setInitialPuzzle(deserializePuzzle(snapshot.val().initialPuzzle));
            });*/
        firebase.database().ref(`anonymousPlayers/${getAnonymousId()}/sudokus/${params.sudokuId}/puzzle`).on('value', (snapshot) => {
            setPuzzle(deserializePuzzle(snapshot.val()));
        });
        firebase.database().ref(`anonymousPlayers/${getAnonymousId()}/sudokus/${params.sudokuId}/name`).on('value', (snapshot) => {
            setName(snapshot.val());
        });
        firebase.database().ref(`anonymousPlayers/${getAnonymousId()}/sudokus/${params.sudokuId}/initialPuzzle`).on('value', (snapshot) => {
            setInitialPuzzle(deserializePuzzle(snapshot.val()));
        });
        firebase.database().ref(`anonymousPlayers/${getAnonymousId()}/sudokus/${params.sudokuId}/notes`).on('value', (snapshot) => {
            setNotes(snapshot.val());
        });
    }, [params]);

    const handleSudokuChange = (newPuzzle: BoardState) => {
        setPuzzle(newPuzzle);
        firebase.database().ref(`anonymousPlayers/${getAnonymousId()}/sudokus/${params.sudokuId}/puzzle`).set(serializePuzzle(newPuzzle));
    };
    const handleSudokuNotesChange = (newNotes: Notes) => {
        setNotes(newNotes);
        firebase.database().ref(`anonymousPlayers/${getAnonymousId()}/sudokus/${params.sudokuId}/notes`).set(newNotes);
    };

    return <div className={"flex h-full"}>
        <div className={"flex-1"}>
            <Toolbar>
                <h3 className={"text-3xl flex-grow"}>{name || ""}</h3>
                <Button variant="contained" color="primary" onClick={() => {handleSudokuChange(initialPuzzle)}}>Reset</Button>
            </Toolbar>
            <Sudoku
                initialBoardState={initialPuzzle}
                boardState={puzzle}
                onChange={handleSudokuChange}
                onNotesChanges={handleSudokuNotesChange}
                notes={notes}
            />
            <div style={{margin: "auto", width: '10px'}}>
                <Confetti active={!_.contains(serializePuzzle(puzzle), "0")} config={{spread: 90, elementCount: 75, stagger: 2}}/>
            </div>
        </div>
    </div>;
};
