import React, {FunctionComponent} from "react";
import SendIcon from '@material-ui/icons/Send';
import {IconButton} from "@material-ui/core";

export interface ChatInputProps {}

export const ChatInput: FunctionComponent<ChatInputProps> = () => {
    return <>
        <div className={'flex border'}>
            <input className="px-2" placeholder={"Your message..."}/>
            <div className={"py-1"}>
                <IconButton aria-label="send">
                    <SendIcon fontSize="default" />
                </IconButton>
            </div>
        </div>
    </>;
};
