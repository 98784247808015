import React, {FunctionComponent, useState} from 'react';
import {Sidebar} from "./Sidebar";
import Drawer from "@material-ui/core/Drawer/Drawer";
import {ApplicationBar} from "./ApplicationBar";
import {
    Switch,
    Route,
} from "react-router-dom";
import {CreateSudokuPage} from "../CreateSudokuPage";
import {SudokuPage} from "../SudokuPage";
import {PublicSudokusPage} from "../PublicSudokusPage";
import {Edit} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import * as s from "underscore.string";
import {AnonymousNameChooser} from "../anonymousNameChooser";
import {useResponsive} from "../hooks/useResponsive";
import {getPseudonym} from "../utils/StoredPseudonym";
import {SudokuSoloPage} from "../SudokuSoloPage";
import {AnonymSoloSudokusPage} from "../AnonymSoloSudokusPage";
import {useAuthenticationService} from "clean-authentication";

export const items = [
    {
        name: 'home',
        label: 'Public Sudokus',
        path: '/'
    },
    {
        name: 'sudoku-solo-anon',
        label: 'My Solo Sudokus',
        path: '/sudoku/solo/anon/',
    },
    {
        name: 'sudoku-create',
        label: 'Create Sudoku',
        path: '/sudoku/create',
    },
];

export const Layout: FunctionComponent = () => {
    const [nameChooserOpen, setNameChooserOpen] = useState<boolean>(!getPseudonym());
    const {user, isAuthenticated} = useAuthenticationService();
    const {viewport} = useResponsive();
    return <>
        {
            viewport === "desktop"
                ? <Drawer
                    variant="permanent"
                    anchor="left"
                    style={{width: '300px'}}
                >
                    <AnonymousNameChooser open={nameChooserOpen} onClose={() => setNameChooserOpen(false)}/>
                    <img className={"rounded-full my-4 w-1/2 mx-auto"} src={`https://avatars.dicebear.com/v2/bottts/${s.slugify(getPseudonym())}.svg`} alt={"avatar"}/>
                    <h3 className={'text-2xl mx-auto'}>{getPseudonym()}<IconButton onClick={() => setNameChooserOpen(true)}><Edit/></IconButton></h3>
                    {
                        isAuthenticated && user && user.email
                            ? <div className={'m-2 text-xs mx-auto text-gray-700'}>{user.email}</div>
                            : null
                    }
                    <Sidebar items={items}/>
                </Drawer>
                : ""
        }

        <ApplicationBar />
        <div className="p-4" style={{marginLeft: viewport === "desktop" ? '300px' : 0, minHeight: "calc(100vh - 64px)"}}>
            <Switch>
                <Route path="/sudoku/create">
                    <CreateSudokuPage/>
                </Route>
                <Route exact path="/">
                    <PublicSudokusPage/>
                </Route>
                <Route path="/sudoku/public/:id">
                    <SudokuPage/>
                </Route>
                <Route exact path="/sudoku/solo/anon">
                    <AnonymSoloSudokusPage/>
                </Route>
                <Route path="/sudoku/solo/anon/:sudokuId">
                    <SudokuSoloPage/>
                </Route>
            </Switch>
        </div>
    </>;
};
