import React, {FunctionComponent} from "react";
import * as _ from "underscore";
import {Card} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import {Sudoku} from "./sudoku/Sudoku";
import {deserializePuzzle} from "./sudoku/SudokuHelperMethods";
import CardActions from "@material-ui/core/CardActions/CardActions";
import Button from "@material-ui/core/Button";
import {SudokuModelMap} from "./SudokuModel";

export interface SudokuListProps {
    puzzleModels: SudokuModelMap;
    onOpen: (id: string) => void;
}

export const SudokuList: FunctionComponent<SudokuListProps> = ({puzzleModels, onOpen}) => {
    return <>
        <div className={"flex flex-wrap"}>
            {
                _.map(puzzleModels, (data, key) => {
                    return <div className="p-2" key={key}>
                        <Card style={{width: "200px", height: "300px"}}>
                            <CardContent style={{width: "200px", height: "250px"}}>
                                <h3 className={'truncate text-xl'}>{data.name || "Sudoku"}</h3>
                                <div style={{backgroundColor: "whitesmoke", transform: "scale(0.3)", transformOrigin: "0 0"}}>
                                    <Sudoku boardState={deserializePuzzle(data.puzzle)} initialBoardState={deserializePuzzle(data.initialPuzzle)} readonly />
                                </div>
                            </CardContent>
                            <CardActions>
                                <Button size="small" onClick={() => onOpen(key)}>Open</Button>
                            </CardActions>
                        </Card>
                    </div>
                })
            }
        </div>
    </>;
};
