import React, {createContext, useState, useEffect, useContext, FunctionComponent} from 'react';

const viewportContext = createContext<{width: number, height: number}>({width: window.innerWidth, height: window.innerHeight});

export const ViewportProvider: FunctionComponent = ({ children }) => {
    // This is the exact same logic that we previously had in our hook

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return (
        <viewportContext.Provider value={{ width, height }}>
            {children}
        </viewportContext.Provider>
    );
};

export const useViewport = () => {
    const { width, height } = useContext(viewportContext);
    return { width, height };
};
