import React, {FunctionComponent} from "react";

type ChatMessageType = "sent" | "received" | "system";

export interface ChatMessageProps {
    date: string;
    content: string;
    type: ChatMessageType;
}

export const ChatMessage: FunctionComponent<ChatMessageProps> = ({date, content, type}) => {
    return <div className={`ChatMessage ${type}`}>
        {date}
        {content}
    </div>;
};
