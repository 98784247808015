import {useViewport} from "./useViewport";

export type Viewport = "phone" | "tablet" | "desktop";
export type Orientation = "landscape" | "portrait";

export const useResponsive = () => {
    const { width, height } = useViewport();
    const viewport: Viewport = width < 1200 ? (width < 480 ? "phone" :"tablet") : "desktop";
    const orientation: Orientation = width/height >= 1 ? "landscape" : "portrait";
    return { viewport, orientation, width, height };
};
