import React, {FunctionComponent, useState} from 'react';
import {Square} from './Square';
import {BoardState} from "./SudokuHelperMethods";
import {useHotkeys} from "react-hotkeys-hook";
import {SudokuPad} from "./SudokuPad";
import {useResponsive} from "../hooks/useResponsive";

export interface BoardPuzzleProps {
    onSquareValueChange: (rowIndex: number, columnIndex: number, newValue: ValidSudokuInput, isNote: boolean) => void;
    boardState: BoardState;
    initialBoardState: BoardState;
    readonly?: boolean;
    notes: Notes;
}

export type SquareLocation = {
    row: number;
    column: number;
    editable: boolean;
};

export type Notes = {[key: string]: ValidSudokuInput[]};

export type ValidSudokuInput = "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "0";

export const BoardPuzzle: FunctionComponent<BoardPuzzleProps > = (props) => {
    const [selectedSquare, setSelectedSquare] = useState<SquareLocation>();
    const [noteMode, setNoteMode] = useState<boolean>(false);
    const {viewport} = useResponsive();

    const handleSquareValueChange = (newValue: ValidSudokuInput) => {
        if (selectedSquare && selectedSquare.editable) {
            if (!noteMode || (noteMode && props.boardState[selectedSquare.row][selectedSquare.column].cellValue === '0')) {
                props.onSquareValueChange(selectedSquare.row, selectedSquare.column, newValue, noteMode);
            }
        }
    };

    useHotkeys("backspace", () => handleSquareValueChange("0"), {}, [selectedSquare]);
    useHotkeys("1,2,3,4,5,6,7,8,9,shift+1,shift+2,shift+3,shift+4,shift+5,shift+6,shift+7,shift+8,shift+9", (event, {key}) => handleSquareValueChange(key[key.length-1] as ValidSudokuInput), {}, [selectedSquare, noteMode]);
    useHotkeys("down", () => {
        if (selectedSquare && selectedSquare.row < 8) {
            const row = selectedSquare.row + 1;
            const column = selectedSquare.column;
            const editable = props.initialBoardState[row][column].cellValue === "0";
            setSelectedSquare({row, column, editable});
        }
    }, [selectedSquare]);
    useHotkeys("up", () => {
        if (selectedSquare && selectedSquare.row > 0) {
            const row = selectedSquare.row - 1;
            const column = selectedSquare.column;
            const editable = props.initialBoardState[row][column].cellValue === "0";
            setSelectedSquare({row, column, editable});
        }
    }, [selectedSquare]);
    useHotkeys("left", () => {
        if (selectedSquare && selectedSquare.column > 0) {
            const row = selectedSquare.row;
            const column = selectedSquare.column - 1;
            const editable = props.initialBoardState[row][column].cellValue === "0";
            setSelectedSquare({row, column, editable});
        }
    }, [selectedSquare]);
    useHotkeys("right", () => {
        if (selectedSquare && selectedSquare.column < 8) {
            const row = selectedSquare.row;
            const column = selectedSquare.column + 1;
            const editable = props.initialBoardState[row][column].cellValue === "0";
            setSelectedSquare({row, column, editable});
        }
    }, [selectedSquare]);

    useHotkeys("*", (event) => {
        if (event.key === "Shift") {
            setNoteMode(true);
            console.log(event);
        }
    }, {
        keydown: true,
        keyup: false,
    },[setNoteMode, noteMode] );
    useHotkeys("*", (event) => {
        if (event.key === "Shift") {
            console.log(event);
            setNoteMode(false);
        }
    }, {
        keydown: false,
        keyup: true,
    },[setNoteMode, noteMode]);

    const generateBoard = () => {
        const board = [];
        const boardState: BoardState = props.boardState;

        for(let rowIndex=0; rowIndex<boardState.length; rowIndex++) {
            let currRow = [];
            for(let columnIndex=0; columnIndex<boardState[rowIndex].length; columnIndex++) {
                const editable = props.initialBoardState[rowIndex][columnIndex].cellValue === "0";
                currRow.push(<Square
                    key={"" + rowIndex + columnIndex}
                    value={boardState[rowIndex][columnIndex].cellValue}
                    editable={editable}
                    rowIndex={rowIndex}
                    colIndex={columnIndex}
                    selectedSquare={selectedSquare}
                    size={viewport === "desktop" ? "normal" : "small"}
                    selectedValue={!!selectedSquare ? boardState[selectedSquare.row][selectedSquare.column].cellValue : undefined}
                    onClick={() => setSelectedSquare({row: rowIndex, column: columnIndex, editable})}
                    notes={props.notes["" + rowIndex + columnIndex]}
                />);
            }
            board.push(<tr key = {rowIndex}>{currRow}</tr>);
        }
        return board;
    };

    return <div>
        <table className = "Board">
            <tbody>
            {generateBoard()}
            </tbody>
        </table>
        {
            props.readonly
                ? ""
                : <SudokuPad onClick={handleSquareValueChange} onNoteModeClick={() => setNoteMode(!noteMode)} noteMode={noteMode}/>
        }
    </div>;
};
