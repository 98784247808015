import React, {FunctionComponent} from "react";
import {ValidSudokuInput} from "./BoardPuzzle";
import * as _ from 'underscore';

export interface SquareNotesProps {
    value: ValidSudokuInput[];
}

export const SquareNotes: FunctionComponent<SquareNotesProps> = ({value}) => {
    return <>
        <div className={"absolute p-1 inset-0 flex-col border text-xs text-gray-500 flex justify-between leading-none"}>
            <div className={"flex justify-between"}>
                <SquareNote currentNotes={value} note={'1'}/>
                <SquareNote currentNotes={value} note={'2'}/>
                <SquareNote currentNotes={value} note={'3'}/>
            </div>
            <div className={"flex justify-between"}>
                <SquareNote currentNotes={value} note={'4'}/>
                <SquareNote currentNotes={value} note={'5'}/>
                <SquareNote currentNotes={value} note={'6'}/>
            </div>
            <div className={"flex justify-between"}>
                <SquareNote currentNotes={value} note={'7'}/>
                <SquareNote currentNotes={value} note={'8'}/>
                <SquareNote currentNotes={value} note={'9'}/>
            </div>
        </div>
    </>;
};

export interface SquareNoteProps {
    currentNotes: ValidSudokuInput[];
    note: ValidSudokuInput;
}

const SquareNote: FunctionComponent<SquareNoteProps> = ({currentNotes, note}) => {
    return <div className={`note ${_.contains(currentNotes, note) ? "" : "invisible"}`}>{note}</div>
};
