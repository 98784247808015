import React, {FunctionComponent, useEffect} from 'react';
import './App.css';
import {Route, Switch} from "react-router";
import {Layout} from "./appLayout/Layout";
import {SignIn} from "./AuthPages/SignIn";
import {HashRouter as Router} from "react-router-dom";
import {AuthenticationServiceContext, FirebaseAuthenticationService} from "clean-authentication";
import {SignUp} from "./AuthPages/SignUp";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const App: FunctionComponent = () => {
    return (
        <AuthenticationServiceContext.Provider value={new FirebaseAuthenticationService()}>
            <Router>
                <Switch>
                    <Route exact path={"/auth/signin"}>
                        <SignIn/>
                    </Route>
                    <Route exact path={"/auth/signup"}>
                        <SignUp/>
                    </Route>
                    <Route path={'/'}>
                        <Layout/>
                    </Route>
                </Switch>
            </Router>
            <ToastContainer/>
        </AuthenticationServiceContext.Provider>
    );
};
